@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #edf5fa;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  composes: h6 from global;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.favorite {
  position: absolute;
  bottom: 20px;
  right: 20px;
  & svg {
    & path {
      stroke: #fff;
    }
  }
}

.cardMessages {
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  border-radius: 0px 0px 4px 4px;
  background: rgba(48, 101, 161, 0.54);
  text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 9px;
  z-index: 2;
  &.bgDark {
    background: var(
      --black-shades-main-text,
      linear-gradient(0deg, #000 0%, #000 100%),
      linear-gradient(0deg, #4a4a4a 0%, #4a4a4a 100%),
      #4a4a4a
    );
  }
}
