@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 152.381% */
  letter-spacing: -0.75px;
  text-decoration-line: underline;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0px;
  }
}

.listingPrice {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #4a4a4a;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.priceList {
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
  }
}

.offerPrice {
  color: #000;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -0.75px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.price {
  composes: h2 from global;

  color: var(--marketplaceColor);
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.author {
  composes: h4 from global;
  margin: 24px 0px 32px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }
}

.authorName {
  color: var(--matterColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.305px;
}

.providerAvatar {
  margin-right: 10px;
  width: 37px;
  height: 37px;
}

.starRatings {
  & svg {
    width: 15px !important;
    height: 15px !important;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.reportButton {
  color: var(--marketplaceColorDark);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: underline;
}
.orderColumnForProductLayout {
  padding: 0 22px;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: auto;
    /* flex-basis: 300px; */
    flex-shrink: 0;
    /* padding: 0; */
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-right: 0px;
  padding: 5px 0px;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: -0.75px;
  @media (--viewportMedium) {
    color: var(--marketplaceColor);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    border-bottom: 1px solid #8e8e8e;
    margin-bottom: 12px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}
