.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  padding: 9px 16px 9px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 40px;
}
