@import '../assets/sanitize.css';
@import './customMediaQueries.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */
  --marketplaceColor: #478cbc;
  /* hsl(260, 63%, 46%) */
  --marketplaceColorLight: hsl(205, 47%, 56%);
  /* hsl(260, 63%, 56%), +10L from marketplaceColor */
  --marketplaceColorDark: #3065a1;
  /* hsl(260, 63%, 26%), -20L from marketplaceColor */

  --marketplaceInitialDefaultColor: #3065a1;
  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23a02cbf;

  --successColor: #2ecc71;
  --successColorDark: #61a60e;
  --successColorLight: #f0fff6;
  --failColor: #d7282f;
  --failColorLight: #fff0f0;
  --attentionColor: #e87200;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColorAlmostDark: #1d1d1f;
  --matterColor: #4a4a4a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #f8f7f7;
  --matterColorBright: #edf5fa;
  --matterColorLight: #ffffff;
  --greyColorLight: #f1f0f0;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'Avenir', 'Inter', 'poppins', Helvetica, Arial, sans-serif;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing units ================ */

  --contentMaxWidth: 1680px;
  /* calc(Global width (1200px) + (Horizontal padding (32px) * 2) */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 96px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 168px;

  /* ================ PageBuilder ================ */
  /* --carouselWidth will be updated dynamically through JS */
  --carouselWidth: 100vw;
}

/* ================ Global element styles ================ */
/**
 * Here are the global styles: for elements as well as some
 * global classes that are composed into component styles.
 *
 * NOTE: components use CSS Modules for styles.
 */

body {
  font-family: Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: #fff;
}

::selection {
  background: var(--marketplaceColor);
  /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor);
  /* Gecko Browsers */
  color: var(--matterColorLight);
}

a,
.a {
  /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;
}

a:hover,
.a:hover {
  text-decoration: underline;
  cursor: pointer;
}

h1,
.h1 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 40px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 48px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

h2,
.h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;

  @media (--viewportMedium) {
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 21px;
    margin-bottom: 19px;
  }
}

h3,
.h3 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

h4,
.h4 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

h5,
.h5 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}

h6,
.h6 {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 6px;
  }
}

p,
pre,
.marketplaceBodyFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

html,
li,
.marketplaceDefaultFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }
}

/* ================ Normalisations ================ */

html {
  /* font is set earlier */
  color: var(--matterColor);
  padding: 0;
  margin: 0;
  scroll-padding-top: calc(var(--topbarHeight) + 1px);

  @media (--viewportMedium) {
    scroll-padding-top: calc(var(--topbarHeightDesktop) + 1px);
  }
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  font-family: 'Inter', Arial, sans-serif;
  display: block;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

button {
  font-family: 'Inter', Arial, sans-serif;
}

/* marketplaceInputFontStyles */
select,
input,
textarea {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  background-color: #fff;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }
}

select {
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 8px 18px 8px;
  background-color: #fff;
  /* Unset user agent styles */
  appearance: none;

  /* Borders */
  border: none;
  border: 0.5px solid #474747;
  border-radius: 4px;

  /* Background */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7' fill='none'%3E%3Cpath d='M6.58352 5.66009L12.0335 0.450562C12.0864 0.399655 12.1494 0.359429 12.2189 0.332266C12.2883 0.305103 12.3628 0.291555 12.4378 0.292426C12.5128 0.293296 12.5868 0.308567 12.6556 0.337333C12.7243 0.366099 12.7863 0.407776 12.8379 0.459895C12.9437 0.566501 13.0018 0.708366 13 0.855343C12.9981 1.00232 12.9365 1.14283 12.8282 1.247L6.97518 6.84152C6.92267 6.89213 6.86013 6.93222 6.79124 6.95943C6.72235 6.98663 6.64849 7.00042 6.57397 6.99999C6.49944 6.99956 6.42576 6.9849 6.35722 6.95689C6.28868 6.92888 6.22666 6.88808 6.17479 6.83686L0.165785 0.948337C0.0594373 0.842921 0 0.702103 0 0.555562C0 0.40902 0.0594373 0.268202 0.165785 0.162786C0.218044 0.111278 0.28057 0.0703306 0.34966 0.0423691C0.41875 0.0144075 0.492999 0 0.568011 0C0.643023 0 0.717272 0.0144075 0.786362 0.0423691C0.855452 0.0703306 0.917978 0.111278 0.970237 0.162786L6.58352 5.66009Z' fill='black'/%3E%3C/svg%3E");
  background-size: 16px 8px;
  background-position: right 1rem center;

  /* Effects */

  cursor: pointer;
  transition: border-bottom-color var(--transitionStyle);

  @media (--viewportMedium) {
    padding: 3px 16px 3px 16px;
  }
}

select:hover,
select:focus {
  border-bottom-color: var(--matterColor);
  outline: none;
}

input,
textarea,
.marketplaceInputStyles {
  /* Dimensions */
  font-family: 'Inter';
  display: block;
  width: 100%;
  margin: 0;
  padding: 9px 16px 9px 16px;
  font-size: 14px;
  /* Borders */
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-weight: var(--fontWeightMedium);
  border: 0.5px solid #474747;
  border-radius: 4px;
  line-height: 20px;

  &::placeholder {
    color: var(--matterColorAnti);
  }

  /* Effects */

  transition: border-bottom-color var(--transitionStyle);

  @media (--viewportMedium) {
    padding: 9px 16px 9px 16px;
  }
}

input:hover,
input:focus,
textarea:hover,
textarea:focus,
.marketplaceInputStyles:hover,
.marketplaceInputStyles:focus {
  border-bottom-color: var(--matterColor);
  outline: none;
}

textarea {
  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 2px 0 8px 0;

  &::placeholder {
    padding: 0 0 0 0;
  }

  @media (--viewportMedium) {
    padding: 6px 0 8px 0;

    &::placeholder {
      padding: 0 0 0 0;
    }
  }
}

.fontsLoaded {
  & body {
    font-family: 'Avenir', 'Inter', 'poppins', Helvetica, Arial, sans-serif;
  }

  & button {
    font-family: 'Avenir', 'Inter', 'poppins', Helvetica, Arial, sans-serif;
  }
}

/* ================ Plain global CSS glasses ================ */

/* Full screen Background image located in root-folder/src/assets */
.defaultBackgroundImage {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../assets/background-1440.png');

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.defaultBackgroundImageForDesktop {
  /* Gradient direction and overlaying the black color on top of the image for better readability */

  background: url('../assets/background-1440.png');

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;

  @media (--viewportLarge) {
    border-radius: 0px;
    border-left: solid 36px var(--matterColorLight);
    border-right: solid 36px var(--matterColorLight);
  }
}

/* ================ Hero title ================ */

.marketplaceHeroTitleFontStyles {
  font-weight: var(--fontWeightBold);
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.7px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 25px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -1.5px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 25px;
    margin-bottom: 23px;
  }
}

/* ================ Other fonts ================ */

.marketplaceSmallFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.marketplaceTinyFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
}

.marketplaceMessageFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
}

.marketplaceMessageDateFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 12px;
    line-height: 24px;
  }
}

.marketplaceTxTransitionFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.marketplaceSearchFilterLabelFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.marketplaceSearchFilterSublabelFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 18px;
}

.marketplaceListingAttributeFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
}

/* ================ Clearfix solution ================ */

.clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/* ================ Buttons ================ */

/**
 * For actual buttons, use components: <Button>, <PrimaryButton>, <SecondaryButton>
 *
 * To create button look-and-feel for links, etc. combine button classes in CSS Modules:
 * 
 * default button:
 *   composes: button buttonFont buttonText buttonBorders buttonColors from global;
 * primary button:
 *   composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
 * secondary button:
 *   composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;
 */

/* Button styles */
/* Basic styles for button: dimensions, paddings, behaviour, etc. */
.button {
  display: block;
  width: 100%;
  min-height: 48px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  padding: 17px 0 17px 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  @media (--viewportMedium) {
    padding: 20px 0 20px 0;
    min-height: 56px;
  }
}

.button:focus,
.button:hover {
  outline: none;
  box-shadow: var(--boxShadowButton);
}

.button:disabled {
  box-shadow: none;
  cursor: not-allowed;
}

/* Button font */
/* Default font style for buttons */
.buttonFont {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Button text styles */
/* Default button text is centered without decorations */
.buttonText {
  text-align: center;
  text-decoration: none;
}

.buttonText:focus,
.buttonText:hover {
  text-decoration: none;
}

/* Button borders */
/* Default button has no borders */
.buttonBorders {
  border: none;
  border-radius: var(--borderRadius);
}

/* Secondary button has clear borders */
.buttonBordersSecondary {
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  border-radius: var(--borderRadius);
}

.buttonBordersSecondary:hover,
.buttonBordersSecondary:focus {
  border-color: var(--matterColorAnti);
}

.buttonBordersSecondary:disabled {
  border-color: var(--matterColorNegative);
}

/* Button colors */
/* Default button uses marketplace color */
.buttonColors {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.buttonColors:focus,
.buttonColors:hover {
  background-color: var(--marketplaceColorDark);
}

.buttonColors:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
}

/* Button colors: primary */
.buttonColorsPrimary {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.buttonColorsPrimary:hover,
.buttonColorsPrimary:focus {
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
}

.buttonColorsPrimary:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
}

/* Button colors: secondary */
.buttonColorsSecondary {
  background-color: var(--matterColorLight);
  color: var(--matterColor);
}

.buttonColorsSecondary:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
}

/* ================ Modals ================ */

.marketplaceModalRootStyles {
  /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

  /* AuthenticationPage's root uses flexbox */
  display: flex;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
  }
}

.marketplaceModalFormRootStyles {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}

.marketplaceModalBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;

  @media (--viewportMedium) {
    flex-basis: 480px;
    border-radius: 4px;

    flex-grow: 0;
    /* min-height: 568px; */
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 6.5vh;
  }
}

.marketplaceModalInMobileBaseStyles {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

/* Modal title */
.marketplaceModalTitleStyles {
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    letter-spacing: -0.9px;
    margin: 0;
  }
}

/* Modal message */
.marketplaceModalParagraphStyles {
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }
}

/* Bottom wrapper for the modal */
.marketplaceModalBottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.marketplaceModalBottomWrapperText {
  margin-top: -24px;
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.marketplaceModalHelperText {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  -webkit-font-smoothing: subpixel-antialiased;

  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalHelperLink {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;

  -webkit-font-smoothing: subpixel-antialiased;

  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

/* Modal Close icon */

.marketplaceModalCloseStyles {
  /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  @media (--viewportMedium) {
    padding: 27px 30px;
    position: absolute;
  }

  /* Colors */
  background-color: transparent;
  color: var(--matterColor);

  cursor: pointer;
}

.marketplaceModalCloseStyles:enabled:hover {
  background-color: transparent;
  color: var(--matterColorDark);
  box-shadow: none;
  text-decoration: none;
}

.marketplaceModalCloseStyles:enabled:active {
  background-color: transparent;
  color: var(--matterColorDark);
}

.marketplaceModalCloseStyles:disabled {
  background-color: transparent;
}

.marketplaceModalIconStyles {
  height: 48px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.marketplaceModalCloseText {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  margin: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.marketplaceModalCloseIcon {
  display: inline-block;
  margin-left: 8px;

  /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;

  @media (--viewportMedium) {
    margin-top: 2px;
  }
}

.marketplaceModalErrorStyles {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin-top: 24px;
  color: var(--failColor);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.marketplaceModalPasswordMargins {
  /* Leave space between the input and the button below when the
  viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
