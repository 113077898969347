.modalTitle {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-bottom: 40px;

  & li {
    & > div {
      cursor: pointer;
    }
  }

  & .iconButton {
    background-color: #040809;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
}

.twitterButton {
  & button {
    width: 40px;
    height: 40px;
    background: #000;
    background-color: #000 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    & path {
      fill: #fff;
    }
  }
}

.whatsAppButton {
  & button {
    width: 40px;
    height: 40px;
    background: #000;
    background-color: #000 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      fill: #fff;
    }
  }
}
