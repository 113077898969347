@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    width: 100%;
    position: relative;
  }
  & .icon {
    position: absolute;
    left: 0;
    & svg {
      height: 30px;
      width: 30px;
    }
    & path {
      fill: #fff !important;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}
/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 11px;
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.305px;
  flex-shrink: 0;
  padding: 0 22px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  border-right: 1px solid #000;
  min-height: 25px;
  &.bag {
    &:after {
      content: unset !important;
    }
  }
  &.linkGap {
    padding: 0 27px 0 27px;
    gap: 3px;
    border-right: 0;
    position: relative;
    @media (max-width: 1128px) {
      padding: 0 16px 0 16px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 25px;
      background: var(--marketplaceInitialDefaultColor);
      top: 10px;
    }
  }
  &.flexRow {
    flex-direction: row;
  }
  & .createListing {
    border-bottom: 1px solid var(--marketplaceColor);
    font-weight: 500;
  }
  &:hover {
    color: var(--marketplaceColorDark);
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cartItemWrappper {
  position: relative;
  & .cartCount {
    position: absolute;
    padding: 5px;
    right: -4px;
    top: -4px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--marketplaceColorDark);
    font-size: 12px;
    line-height: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      color: var(--marketplaceColorDark);
      font-size: 12px;
    }
    &:before {
      content: '';
      display: block;
      margin-top: calc(50% - 12px);
    }
  }
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  width: 100%;
  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  display: block;
  & span {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
}

.navigationLinkAccount {
  composes: navigationLink;
  border: 0;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
  color: #ddd;
  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
  color: #ddd;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-weight: 600;
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-weight: 600;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  /* border-left: 5px solid black; */
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.faqsec {
  padding: 0px;
  margin: 0px;
  @media (max-width: 767px) {
    padding: 0 0px;
  }
}

.faqwrapper {
  & :global(.accordion) {
  }
  & .faqItemCount {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #222a32;
    padding-right: 8px;
    border-right: 3px solid #1aae9f;
    margin-right: 17px;
    width: 40px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
      min-width: 40px;
    }
  }
  /* & :global(.accordion__item + .accordion__item) {
    border-top: 1px solid #d1d1d1;
  } */
  & :global(.accordion__button) {
    cursor: pointer;
    padding: 5px 20px 5px 0;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--black-shades-main-text, #4a4a4a);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
    letter-spacing: -0.75px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & :global(.accordion__button:hover) {
    /* background-color: #ddd; */
  }
  & :global(.accordion__button:before) {
    display: inline-block;
    content: '';
    height: 27px;
    width: 22px;
    position: absolute;
    right: 0px;
    top: 3px;
    transform: scale(0.8);
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33' fill='none'%3E%3Cpath d='M16.3299 11.9163L5.26336 22.968C5.15592 23.076 5.0279 23.1613 4.88689 23.2189C4.74588 23.2766 4.59474 23.3053 4.44242 23.3035C4.2901 23.3016 4.13969 23.2692 4.00012 23.2082C3.86055 23.1472 3.73464 23.0588 3.62985 22.9482C3.41517 22.722 3.29714 22.4211 3.30084 22.1093C3.30453 21.7975 3.42967 21.4994 3.64965 21.2784L15.5346 9.40994C15.6412 9.30257 15.7682 9.21754 15.9081 9.15982C16.048 9.1021 16.198 9.07284 16.3493 9.07376C16.5006 9.07469 16.6503 9.10577 16.7894 9.16519C16.9286 9.22461 17.0545 9.31118 17.1599 9.41984L29.3616 21.912C29.5776 22.1356 29.6982 22.4344 29.6982 22.7452C29.6982 23.0561 29.5776 23.3549 29.3616 23.5785C29.2555 23.6878 29.1285 23.7746 28.9882 23.8339C28.8479 23.8933 28.6972 23.9238 28.5449 23.9238C28.3925 23.9238 28.2418 23.8933 28.1015 23.8339C27.9612 23.7746 27.8342 23.6878 27.7281 23.5785L16.3299 11.9163Z' fill='white'/%3E%3C/svg%3E");
  }
  & :global(.accordion__button[aria-expanded='true']::before),
  & :global(.accordion__button[aria-selected='true']::before) {
    width: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33' fill='none'%3E%3Cpath d='M16.6701 21.0837L27.7366 10.032C27.8441 9.92402 27.9721 9.83868 28.1131 9.78105C28.2541 9.72343 28.4053 9.69469 28.5576 9.69654C28.7099 9.69838 28.8603 9.73078 28.9999 9.7918C29.1395 9.85283 29.2654 9.94124 29.3701 10.0518C29.5848 10.278 29.7029 10.5789 29.6992 10.8907C29.6955 11.2025 29.5703 11.5006 29.3503 11.7216L17.4654 23.5901C17.3588 23.6974 17.2318 23.7825 17.0919 23.8402C16.952 23.8979 16.802 23.9272 16.6507 23.9262C16.4994 23.9253 16.3497 23.8942 16.2106 23.8348C16.0714 23.7754 15.9455 23.6888 15.8401 23.5802L3.6384 11.088C3.42245 10.8644 3.30176 10.5656 3.30176 10.2548C3.30176 9.94388 3.42245 9.64515 3.6384 9.42151C3.74451 9.31224 3.87148 9.22537 4.01177 9.16606C4.15206 9.10674 4.30283 9.07617 4.45515 9.07617C4.60747 9.07617 4.75823 9.10674 4.89853 9.16606C5.03882 9.22537 5.16578 9.31224 5.2719 9.42151L16.6701 21.0837Z' fill='white'/%3E%3C/svg%3E");
  }
  & :global(.accordion__button[aria-expanded='true']) {
    font-weight: 600;
  }
  [hidden] {
    display: none;
  }
  & :global(.accordion__panel) {
    padding: 0px 10px 0px 0px;
    /* background: #eef7ff; */
    animation: fadein 0.35s ease-in;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #445463;
    margin-top: 9px;
    & ul {
      list-style: disc;
      margin-left: 20px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.accordionHeader {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  & span {
  }
}

.iconAccount {
  & path {
    fill: transparent !important;
  }
}

.itemList {
  margin: 0;
  padding: 0;
  margin-right: 17px;
  padding-right: 9px;
  max-height: 420px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColorDark);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.listItem {
  /* Layout */

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
  padding: 12px 12px 12px 32px;
  overflow: hidden;
  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
  }
  & > div {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      height: 1px;
      background: #e0e0e0;
      bottom: -12px;
    }
  }
  &:hover {
    background: #edf5fa;
  }
}

.OfferMenuContent {
  /* min-width: 390px !important; */
  padding-top: 20px;

  padding-bottom: 24px;
}

.offerDropwownHeader {
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 6px 31px;
  & h3 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}

.storeItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
  & * {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 343.75% */
    letter-spacing: -1px;
    text-decoration-line: underline;
  }
}

.buttonSeller {
  border-radius: 4px;
  background: var(--black-shades-light-blue-background, #edf5fa);
  min-height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  color: #3065a1;
  font-size: 20px;
  font-style: normal;
  line-height: 55px; /* 275% */
  letter-spacing: -1px;
  text-decoration-line: underline;
  &.buttonSeller2 {
    text-decoration-line: none;
  }
  & a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  position: relative;
  & .availableEarnings {
    color: var(--marketplaceColorDark);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px; /* 343.75% */
    letter-spacing: -1px;
    text-decoration-line: none;
  }
  & .totalAmount {
    color: var(--marketplaceColorDark);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px; /* 275% */
    letter-spacing: -1px;
    text-decoration-line: underline;
  }

  & .arrowRight {
    position: absolute;
    right: 29px;
    transform: rotate(270deg);
    & svg {
      & path {
        fill: #3065a1;
      }
    }
  }
}

.category {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  background: #fff;
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 22px;
  margin-top: 33px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  & > div:not(:last-child) {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 1px;
      height: 37.955px;
      background: #3065a1;
      right: -15px;
      top: 15px;
    }
  }

  & > div:nth-child(1) {
    & .activeStore {
      & .icon {
        background: var(--marketplaceColorDark);
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(2) {
    & .activeStore {
      & .icon {
        background: #d7282f;
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(3) {
    & .activeStore {
      & .icon {
        background: #e87200;
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(4) {
    & .activeStore {
      & .icon {
        background: #61a60e;
        border-radius: 50%;
      }
    }
  }
  & .icon {
    position: relative !important;
  }
  & * {
    color: var(--matterColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
}

.profileSettingDiv {
  border-top: 1px solid #fff;
  margin-top: 33px;
  padding-top: 22px;
  & .profileSettingsContent {
    /* padding: 0 24px; */
    & .userName {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 177.778% */
      letter-spacing: -1px;
    }
  }
}

.salesAndFollowersInfo {
  display: flex;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 8px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.starRatings {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 7px;
  @media (max-width: 767px) {
    margin: 12px 0;
  }
  & svg {
    width: 15px !important;
    height: 15px !important;
  }
  & .reviewText {
    color: #fff;
    display: block;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    margin-top: 4px;
    @media (max-width: 767px) {
    }
  }
}
