.skeleton {
  background: linear-gradient(90deg, #b7b7b7 25%, #e6e6e6 37%, #b7b7b7 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}
