@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.itemSold {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(35, 141, 193, 0.5);
    z-index: 1;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;
  border: 2px solid var(--marketplaceColorDark);
  background: #fff;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
  &.sneakerBorder {
    border: 2px solid #d7282f;
  }
  &.streetwearBorder {
    border: 2px solid #e87200;
  }
  &.vintageBorder {
    border: 2px solid #61a60e;
  }
  &.clothingBorder {
    border: 2px solid #3065a1;
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 9px 6px;
  overflow: hidden;
  object-fit: contain;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  margin-top: 4px;
  font-size: 16px;
  & .priceValueCurrency {
    color: #3065a1;
    padding-right: 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 125% */
    letter-spacing: -0.305px;
  }

  & .currency {
    font-weight: 500;
  }
  & .signgleLine {
    display: inline-block;
    width: 2px;
    height: 12px;
    background-color: black;
  }
  & .priceValueSize {
    padding-left: 6px;
    color: #4a4a4a;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.305px;
  }

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceInitialDefaultColor);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 1023px) {
    font-size: 16px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;

  color: var(--matterColor);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  padding-bottom: 10px;
  text-transform: capitalize;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
