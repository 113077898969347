@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 42px 0 0;
  }
}

.salesAndFollowersInfo {
  display: flex;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 8px;

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.starRatings {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 7px;

  @media (max-width: 767px) {
    margin: 12px 0;
  }

  & svg {
    width: 15px !important;
    height: 15px !important;
  }

  & .reviewText {
    color: #fff;
    display: block;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    margin-top: 4px;

    @media (max-width: 767px) {}
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pointerEvents {
  opacity: 0.5;
  flex: 1;
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemUsername {
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.15px;
}

.lastTransitionedAt {
  color: #212121;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 200% */
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.itemAvatar {
  display: flex;
  align-items: center;
  margin-right: 8px;

  @media (--viewportMedium) {
    display: block;
    align-items: unset;
  }

  @media (--viewportLarge) {
    margin-right: 15px;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: 4px;
  border: 2px solid var(--marketplaceInitialDefaultColor);
  background: #fff;
  margin-left: 50px;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.itemList {
  margin: 0;
  padding: 0;
  margin-right: 17px;
  padding-right: 9px;
  max-height: 420px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColorDark);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.listItem {
  /* Layout */

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
  padding: 12px 12px 12px 32px;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {}

  &>div {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      height: 1px;
      background: #e0e0e0;
      bottom: -12px;
    }
  }

  &:hover {
    background: #edf5fa;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 21px 24px 21px 24px;

  @media (--viewportLarge) {
    padding: 20px 36px 20px 27px;
  }

  @media (max-width: 1440px) {
    & svg {
      max-width: 170px;
    }
  }

  @media (max-width: 1023px) {
    padding: 8px 18px 8px 18px;

    & svg {
      max-width: 150px;
    }
  }
}

.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor); */
  border-radius: 0;
  text-decoration: none;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 49px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  flex-basis: 561px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: 59px;
  border-radius: 8px;
  background: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 24px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 11px;
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.305px;
  flex-shrink: 0;
  padding: 0 22px 0 12px;
  color: var(--marketplaceInitialDefaultColor);
  margin-top: 0;
  margin-bottom: 0;
  border-right: 1px solid #000;
  min-height: 25px;

  &.bag {
    &:after {
      content: unset !important;
    }
  }

  &.linkGap {
    padding: 0 27px 0 27px;
    gap: 3px;
    border-right: 0;
    position: relative;

    @media (max-width: 1128px) {
      padding: 0 16px 0 16px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 25px;
      background: var(--marketplaceInitialDefaultColor);
      top: 10px;
    }
  }

  &.flexRow {
    flex-direction: row;
  }

  & .createListing {
    border-bottom: 1px solid var(--marketplaceInitialDefaultColor);
    font-weight: 500;
  }

  &:hover {
    color: var(--marketplaceColorDark);
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.topbarDesktopLabel {
  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 0px 0;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-family: 'Avenir';
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.305px;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);
  text-decoration: underline;

  &:hover {
    color: var(--matterColorDark);

    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  gap: 13px;

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 375px !important;
  padding-top: 20px;

  max-height: calc(100vh - 100px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.listItemsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.OfferMenuContent {
  min-width: 390px !important;
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 24px;
}

.offerDropwownHeader {
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 6px 31px;

  & h3 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  margin: 0 30px 0 28px;
  text-decoration: underline;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  border-radius: 4px;
  background: var(--marketplaceInitialDefaultColor);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px 9px;

  color: var(--matterColorLight);

  & span {
    color: var(--matterColorLight);
  }

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;
  }
}

.signup,
.login {
  color: var(--matterColor);
  font-family: 'Avenir';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 111.111% */
  letter-spacing: -0.305px;
  text-decoration-line: underline;

  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 0px 0;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: #fff;
  text-align: left;
  transition: var(--transitionStyleButton);
  display: flex;
  gap: 10px;

  &:hover {
    color: #ddd;
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: #fff;
  font-weight: 900;
  text-decoration: underline;
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.cartItemWrappper {
  position: relative;

  & .cartCount {
    position: absolute;
    padding: 5px;
    right: -4px;
    top: -4px;
    border-radius: 50%;
    background-color: var(--marketplaceInitialDefaultColor);
    color: #fff;
    font-size: 12px;
    line-height: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      margin-top: calc(50% - 12px);
    }
  }
}

/* .sneakers{
  & a{
    & span{
      color:  #D7282F;

    }
  }
}
.streetwear{
  & a{
    & span{
      color:  #E87200;

    }
  }
}
.vintage{
  & a{
    & span{
      color:  #61A60E;

    }
  }
} */

.offerItems {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .aspectRatioWrapper {
    overflow: hidden;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 3px solid#fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.14);

    & img {
      width: 100%;
      object-fit: contain;
      height: auto;
      width: 56px;
      height: 56px;
    }
  }
}

.accountSettingItem {
  padding-left: 80px;
}

.accountSettingDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.storeItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  margin-top: 10px;

  & * {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 343.75% */
    letter-spacing: -1px;
    text-decoration-line: underline;
  }

}

.buttonSeller {
  border-radius: 4px;
  background: var(--black-shades-light-blue-background, #edf5fa);
  min-height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  color: var(--marketplaceColorDark);
  font-size: 20px;
  font-style: normal;
  line-height: 55px;
  /* 275% */
  letter-spacing: -1px;
  text-decoration-line: underline;

  &.buttonSeller2 {
    text-decoration-line: none;
  }

  & a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  position: relative;

  & .availableEarnings {
    color: var(--marketplaceColorDark);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    /* 343.75% */
    letter-spacing: -1px;
    text-decoration-line: none;
  }

  & .totalAmount {
    color: var(--marketplaceColorDark);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 55px;
    /* 275% */
    letter-spacing: -1px;
    text-decoration-line: underline;
  }

  & .arrowRight {
    position: absolute;
    right: 25px;
    transform: rotate(270deg);

    & svg {
      & path {
        fill: var(--marketplaceColorDark);
      }
    }
  }
}

.category {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  background: #fff;
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 22px;
  margin-top: 33px;

  &>div:not(:last-child) {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 1px;
      height: 37.955px;
      background: #3065a1;
      right: -15px;
      top: 15px;
    }
  }

  &>div:nth-child(1) {
    & .activeStore {
      & .icon {
        background: var(--marketplaceColorDark);
        border-radius: 50%;
        cursor: pointer;

      }
    }
  }

  &>div:nth-child(2) {
    & .activeStore {
      & .icon {
        background: #d7282f;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  &>div:nth-child(3) {
    & .activeStore {
      & .icon {
        background: #e87200;
        border-radius: 50%;
        cursor: pointer;

      }
    }
  }

  &>div:nth-child(4) {
    & .activeStore {
      & .icon {
        background: #61a60e;
        border-radius: 50%;
        cursor: pointer;

      }
    }
  }

  & * {
    color: var(--matterColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
  }
}

.profileSettingDiv {
  border-top: 1px solid #fff;
  margin-top: 33px;
  padding-top: 22px;

  & .profileSettingsContent {
    padding: 0 24px;

    & .userName {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 177.778% */
      letter-spacing: -1px;
    }
  }
}

.menuIcon {
  max-width: 20px;
  width: 100%;
}
.purchasePageLink{
  color: #fff;
  text-decoration: none;
}