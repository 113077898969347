@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.itemSold {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(35, 141, 193, 0.5);
    z-index: 1;
  }
}

.removeBtn {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 9;
  margin: 0;
}

.priceText {
  color: var(--marketplaceColor);
  font-size: 16px;
}

.addtoBagBtn {
  margin-top: 20px;
  border: 1px solid var(--marketplaceColor);
  background-color: #fff;
  color: var(--marketplaceColor);
  min-height: 44px;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: var(--marketplaceColor);
  }
  &:focus {
    background-color: #fff;
    color: var(--marketplaceColor);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;
  border: 2px solid var(--marketplaceColor);
  background: #fff;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 9px 6px;
  overflow: hidden;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  font-size: 16px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceInitialDefaultColor);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 1023px) {
    font-size: 16px;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
